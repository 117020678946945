<script setup>
import { ref, onMounted, nextTick, watch } from 'vue';
import { getDataObjectById, getOrCreateDataObject } from 'o365-dataobject';
import { ODataTable } from 'o365-datagrid';
import { confirm } from 'o365-vue-services';
import HazardsCard from 'sja.vue.libraries.components.HazardsCard.vue';
import HazardsSummaryCard from 'sja.vue.libraries.components.HazardsSummaryCard.vue';
import InitialMatrixModal from 'sja.vue.libraries.components.InitialMatrixModal.vue'
import FinalMatrixModal from 'sja.vue.libraries.components.FinalMatrixModal.vue'
import { procDeleteTask, reloadKey } from 'sja.vue.libraries.modules.utilities.js'
import { getOrCreateProcedure } from 'o365-modules';
// import { isMobile } from 'o365-mobile';
// import TasksMobileComponent from 'sja.vue.libraries.mob.TasksMobile.vue';

const props = defineProps({
    itemID: Number,
    sjaID: Number,
    readOnly: Boolean,
    participants: Boolean
});

const dsSJA = getOrCreateDataObject({
    id: `dsSJA_${props.sjaID}`,
    uniqueTable: 'atbv_SJA_SJA',
    maxRecords: -1,
    selectFirstRowOnLoad: true,
    viewName: 'aviw_SJA_SJA',
    allowUpdate: true,
    allowInsert: true,
    allowDelete: true,
    disableLayouts: true,
    fields: [
        { name: 'PrimKey', },
        { name: 'ID', },
        { name: 'Step_ID', },
        { name: 'Name', },
        { name: 'Description', },
        { name: 'Planned', },
        { name: 'Completed', },
        { name: 'OrgUnit_ID', },
        { name: 'OrgUnitIdPath', },
        { name: 'OrgUnit', },
        { name: 'Responsible_ID', },
        { name: 'Responsible', },
        { name: 'IsTemplate', },
        { name: 'Checklist_ID', },
        { name: 'CheckList_Editable', },
        { name: 'Checklist', }
    ],
    whereClause: "ID = " + props.sjaID
});

const dsTasks = getOrCreateDataObject({
    id: `dsTasks_${props.sjaID}`,
    uniqueTable: 'atbv_SJA_SJATasks',
    maxRecords: -1,
    viewName: 'aviw_SJA_SJATasks',
    allowUpdate: true,
    allowInsert: true,
    allowDelete: true,
    disableLayouts: true,
    fields: [
        { name: 'PrimKey', },
        { name: 'ID', },
        { name: 'SJA_ID', },
        {
            name: 'TaskNo',
            sortOrder: 1,
            sortDirection: "asc",
        },
        { name: 'Description', },
        { name: 'Responsible_ID', },
        { name: 'Comment', }
    ],
    whereClause: `SJA_ID = ${props.sjaID}`,
    masterDataObject_ID: `dsSJA_${props.sjaID}`,
    masterDetailDefinition: [
        {
            masterField: "ID",
            detailField: "SJA_ID",
            operator: "equals"
        }
    ],
});

let dsHazards = getOrCreateDataObject({
    id: `dsTasksHazards_${props.sjaID}`,
    uniqueTable: 'atbv_SJA_SJATasksHazards',
    maxRecords: -1,
    viewName: 'aviw_SJA_SJATasksHazards',
    allowUpdate: true,
    allowInsert: true,
    allowDelete: true,
    disableLayouts: true,
    fields: [
        { name: 'PrimKey', },
        { name: 'ID', },
        { name: 'Name', },
        { name: 'Color', },
        { name: 'FinalColor', },
        { name: 'Matrix_ID', },
        { name: 'SJATask_ID', },
        { name: 'FinalMatrix_ID', },
        { name: 'ProbabilityNameAndDesc', },
        { name: 'ConsequenceNameAndDesc', },
        { name: 'FinalProbabilityNameAndDesc', },
        { name: 'FinalConsequenceNameAndDesc', },
        { name: 'ProbabilityValue', },
        { name: 'ConsequenceValue', },
        { name: 'SJA_ID', },
        { name: 'FinalProbabilityValue', },
        { name: 'FinalConsequenceValue', },
        { name: 'ExistingActions', },
        { name: 'Consequences', },
        { name: 'Actions', }
    ],
    whereClause: `SJA_ID = ${props.sjaID}`,
});

let dsActions = getOrCreateDataObject({
    id: `dsTasksHazardsActions_${props.sjaID}`,
    uniqueTable: 'atbv_SJA_SJATasksHazardsActions',
    maxRecords: -1,
    viewName: 'aviw_SJA_SJATasksHazardsActions',
    allowUpdate: true,
    allowInsert: true,
    allowDelete: true,
    disableLayouts: true,
    fields: [
        { name: 'PrimKey', },
        { name: 'ID', },
        { name: 'Name', },
        { name: 'Comment', },
        { name: 'IsVerified', },
        { name: 'SJATasksHazard_ID', },
        { name: 'SJA_ID', }
    ],
    whereClause: `SJA_ID = ${props.sjaID}`,
});

let dsResponsible = getOrCreateDataObject({
    id: `dsTasksHazardsActionsResp_${props.sjaID}`,
    uniqueTable: 'atbv_SJA_SJATasksHazardsActionsResponsibles',
    maxRecords: -1,
    viewName: 'aviw_SJA_SJATasksHazardsActionsResponsibles',
    allowUpdate: true,
    allowInsert: true,
    allowDelete: true,
    disableLayouts: true,
    fields: [
        { name: 'PrimKey', },
        { name: 'ID', },
        { name: 'SJATasksHazardsAction_ID', },
        { name: 'Responsible_ID', },
        { name: 'StepAction_ID', },
        { name: 'Completed', },
        { name: 'SJA_ID', },
        { name: 'Name', }
    ],
    whereClause: `SJA_ID = ${props.sjaID}`,
});

const dsConsequencesLkp = getOrCreateDataObject({
    id: `dsConsequencesLkp`,
    maxRecords: -1,
    viewName: 'aviw_SJA_Consequences',
    allowUpdate: true,
    allowInsert: true,
    allowDelete: true,
    disableLayouts: true,
    fields: [
        { name: 'ID', },
        { name: 'Name', },
        { name: 'Value', },
        { name: 'Description', },
        { name: 'ValueAndName', },
        { name: 'NameAndDescription', },
        { name: 'ValueAndNameAndDescription', }
    ],
});

const dsProbabilitiesLkp = getOrCreateDataObject({
    id: `dsProbabilitiesLkp`,
    maxRecords: -1,
    viewName: 'aviw_SJA_Probabilities',
    allowUpdate: true,
    allowInsert: true,
    allowDelete: true,
    disableLayouts: true,
    fields: [
        { name: 'ID', },
        { name: 'Name', },
        { name: 'Value', },
        { name: 'Description', },
        { name: 'ValueAndName', },
        { name: 'NameAndDescription', },
        { name: 'ValueAndNameAndDescription', }
    ],
});

const dsHazardsModalInitial = getOrCreateDataObject({
    id: `dsTasksHazardsModalInitial`,
    uniqueTable: 'atbv_SJA_SJATasksHazards',
    selectFirstRowOnLoad: true,
    maxRecords: -1,
    viewName: 'aviw_SJA_SJATasksHazards',
    allowUpdate: true,
    allowInsert: true,
    allowDelete: true,
    disableLayouts: true,
    fields: [
        { name: 'PrimKey', },
        { name: 'ID', },
        { name: 'Name', },
        { name: 'Color', },
        { name: 'FinalColor', },
        { name: 'Matrix_ID', },
        { name: 'SJATask_ID', },
        { name: 'SJA_ID', },
        { name: 'FinalMatrix_ID', },
        { name: 'ProbabilityNameAndDesc', },
        { name: 'ConsequenceNameAndDesc', },
        { name: 'FinalProbabilityNameAndDesc', },
        { name: 'FinalConsequenceNameAndDesc', },
        { name: 'ProbabilityValue', },
        { name: 'ConsequenceValue', },
        { name: 'FinalProbabilityValue', },
        { name: 'FinalConsequenceValue', }
    ],
});

const dsHazardsModalFinal = getOrCreateDataObject({
    id: `dsTasksHazardsModalFinal`,
    uniqueTable: 'atbv_SJA_SJATasksHazards',
    selectFirstRowOnLoad: true,
    maxRecords: -1,
    viewName: 'aviw_SJA_SJATasksHazards',
    allowUpdate: true,
    allowInsert: true,
    allowDelete: true,
    disableLayouts: true,
    fields: [
        { name: 'PrimKey', },
        { name: 'ID', },
        { name: 'Name', },
        { name: 'Color', },
        { name: 'FinalColor', },
        { name: 'Matrix_ID', },
        { name: 'SJATask_ID', },
        { name: 'SJA_ID', },
        { name: 'FinalMatrix_ID', },
        { name: 'ProbabilityNameAndDesc', },
        { name: 'ConsequenceNameAndDesc', },
        { name: 'FinalProbabilityNameAndDesc', },
        { name: 'FinalConsequenceNameAndDesc', },
        { name: 'ProbabilityValue', },
        { name: 'ConsequenceValue', },
        { name: 'FinalProbabilityValue', },
        { name: 'FinalConsequenceValue', },
        { name: 'ProbabilityValueID', },
        { name: 'ConsequenceValueID', },
        { name: 'FinalProbabilityValueID', },
        { name: 'FinalConsequenceValueID', }
    ],
});

const dsTasksLkp = getOrCreateDataObject({
    id: `dsTasksLkp`,
    maxRecords: -1,
    viewName: 'aviw_SJA_SJATasks',
    allowUpdate: false,
    allowInsert: false,
    allowDelete: false,
    disableLayouts: true,
    fields: [
        { name: 'PrimKey', },
        {
            name: 'ID',
            sortOrder: 1,
            sortDirection: "asc",
        },
        { name: 'SJA_ID', },
        { name: 'SJAName', },
        { name: 'TaskNo', },
        { name: 'Description', },
        { name: 'Responsible_ID', },
        { name: 'Comment', }
    ],
});

const procRenumberItems = getOrCreateProcedure({ id: "procRenumberItems", procedureName: "astp_SJA_TaskItemsRenumbering" });
const procCopyTaskRow = getOrCreateProcedure({ id: "procCopyTaskRow", procedureName: "astp_SJA_CopyTaskRow" });
const procCreateHazardRow = getOrCreateProcedure({ id: "procCreateHazardRow", procedureName: "astp_SJA_CreateHazardRow" });

let prevTaskNo = null;
let disableNew = ref(false);

async function renumberItems(currTaskNo) {
    if (currTaskNo !== prevTaskNo && prevTaskNo !== null) {
        await procRenumberItems.execute({
            SJA_ID: props.sjaID,
            Curr_No: currTaskNo,
            Prev_No: prevTaskNo
        }, function (pErr, pData) {
            if (pErr === null) {
            }
        });
    }
    await dsTasks.load();
}

dsTasks.on("BeforeSave", async function (options, row) {
    prevTaskNo = row.TaskNo
});

dsTasks.on("AfterSave", async function (options, row) {
    if (options.operation === "create") {
        await newRow(row);
    } else {
        await renumberItems(row.TaskNo);
    }
    disableNew.value = false;
    window.location.hash = 'taskRow' + row.ID + '2';
    window.location.hash = '';
});

dsTasks.on("BeforeDelete", async function (options, row) {
    prevTaskNo = row.TaskNo
    options.cancelEvent = true;
    await confirm({
        title: $t("Delete Task"),
        message: $t('Are you sure you wish to delete') + ' ' + row.Description + '?',
        btnTextOk: $t("Yes"),
        btnTextCancel: $t("No"),
    }).then(async () => {
        await procDeleteTask.execute({ PrimKey: row.PrimKey });
        await renumberItems(row.TaskNo + 1);
    }).catch(() => {
    });
});

dsTasks.on("DataLoaded", async function (options, row) {
    await dsHazards.load();
    await dsActions.load();
    await dsResponsible.load();

    if (document.querySelector(`[data-o365-container="N"]`)) {
        document.querySelector(`[data-o365-container="N"]`).classList.add("sticky-bottom", "bg-white", "border-top");
    }

    addEventListeners();
});

onMounted(async () => {
    await dsSJA.load();
    await dsTasks.load();
    addEventListeners();
})

function addEventListeners() {
    let collapseDivs = document.querySelectorAll(".collapse-div");

    collapseDivs.forEach(collapseDiv => {
        let taskRowClass = Array.from(collapseDiv.classList).find(cls => cls.startsWith('taskRow'));
        let taskRowNumber = taskRowClass.match(/\d+/)[0];

        collapseDiv.addEventListener('hidden.bs.collapse', event => {
            onCollapseHidden(taskRowNumber);
        });
        collapseDiv.addEventListener('shown.bs.collapse', event => {
            onCollapseShown(taskRowNumber);
        });
    });
}

async function newRow(row) {
    let dsTasksHazards = null;
    let dsTasksHazardsActions = null;
    let dsTasksHazardsActionsResp = null;
    try {
        await procCreateHazardRow.execute({ ID: row.ID });
        dsTasksHazards = getDataObjectById(`dsTasksHazards_${row.SJA_ID}`);
        dsTasksHazardsActions = getDataObjectById(`dsTasksHazardsActions_${row.SJA_ID}`);
        dsTasksHazardsActionsResp = getDataObjectById(`dsTasksHazardsActionsResp_${row.SJA_ID}`);
        await dsTasksHazards.load();
        await dsTasksHazardsActions.load();
        await dsTasksHazardsActionsResp.load();
    } catch (error) {
        console.error("Error loading data:", error);
    }
}

async function newTask() {
    disableNew.value = true;
    await dsTasks.createNew({ SJA_ID: props.sjaID, TaskNo: dsTasks.data.length + 1 }, true, false)
    await dsTasks.save();
    dsTasks.load();

    const scrollableDiv = document.querySelector(".o365-body-center-cols-viewport");
    scrollableDiv.scrollTop = scrollableDiv.scrollHeight;
};

async function CopyTask(row) {
    try {
        await procCopyTaskRow.execute({ SjaID: props.sjaID, TaskID: row.ID, TaskNo: dsTasks.data.length + 1 });
        await dsTasks.load();
    } catch (error) {
        console.error("Error loading data:", error);
    }

    const scrollableDiv = document.querySelector(".o365-body-center-cols-viewport");
    scrollableDiv.scrollTop = scrollableDiv.scrollHeight;
}

let expandedItems = ref({});
let isButtonDisabled = ref({});

function toggleExpand(rowID) {
    isButtonDisabled.value[rowID] = true;
    expandedItems.value[rowID] = !expandedItems.value[rowID];
}

function onCollapseShown(rowID) {
    isButtonDisabled.value[rowID] = false;
}

function onCollapseHidden(rowID) {
    isButtonDisabled.value[rowID] = false;
}

</script>  

<template>
    <!-- <template v-if="isMobile"> -->
    <!-- <TasksMobileComponent :sjaID="props.sjaID" :readOnly="props.readOnly"/> -->
    <!-- </template> -->
    <!-- <template v-else> -->
    <ODataTable :key="reloadKey" disableNavigation :class="`p-0 tasks-table-${props.sjaID}`"
        :hideNewRecords="props.readOnly" :dataObject="dsTasks" disableColumnMove hideMultiselectColumn noActiveRows
        :hideSystemColumn="props.readOnly" :hideActionColumn="props.readOnly" :noFooter="props.readOnly" hideGridMenu
        load-data-object>
        <OColumn sortable disableResize disableMenu disableColumnMove width="60px" field="TaskNo" v-slot="{ row: task }"
            required :headerName="$t('No.')">
            <div v-if="task.isNewRecord" :disabled="disableNew" class="btn-group me-2">
                <a type="button" class="dropdown-toggle" :title="$t('New Task')" data-bs-toggle="dropdown"
                    aria-expanded="false">
                    {{ $t('New') + ' ' }} <i class="bi bi-plus-circle" />
                </a>
                <ul class="dropdown-menu bg-white border-1 border border-grey">
                    <li><a type="button" @click="newTask()" class="dropdown-item mb-1" :title="$t('Create a new task')"
                            aria-expanded="false">
                            {{ $t('New task') }}
                        </a></li>
                    <ODataLookup @beforeopen="dsTasksLkp.load()" v-if="!props.readOnly" contextField="IdPath"
                        :data-object="dsTasksLkp" :bind="sel => { CopyTask(sel) }">
                        <OColumn field="ID" width="73" sortable></OColumn>
                        <OColumn :headerName="$t('SJA')" field="SJAName" width="300"></OColumn>
                        <OColumn :headerName="$t('Task')" field="Description" width="205"></OColumn>
                        <template #target="scope" @click="scope.open">
                            <a type="button" :ref="scope.target" class="dropdown-item" :title="$t('Copy an existing task')"
                                aria-expanded="false">
                                {{ $t('Copy a task') }}
                            </a>
                        </template>
                    </ODataLookup>
                </ul>
            </div>
            <OContentEditable v-else :isEditable="!props.readOnly" class="w-100 h-100 p-1 text-break"
                :class="{ 'inner-table-editable': !props.readOnly }" v-model="task.TaskNo"
                @blur="if (task.TaskNo && task.Description) { dsTasks.save() }; " />
        </OColumn>
        <OColumn disableResize disableMenu disableColumnMove flexwidth="5" width="150px" field="Description"
            v-slot="{ row: task }" required :headerName="$t('Description')">
            <OContentEditable v-if="!task.isNewRecord" :isEditable="!props.readOnly" class="w-100 h-100 p-1 text-break"
                :class="{ 'inner-table-editable': !props.readOnly }" v-model="task.Description"
                @blur="if (task.TaskNo && task.Description) { dsTasks.save() };" />
        </OColumn>
        <OColumn disableResize disableMenu disableColumnMove flexwidth="95" class="custom-cell" colId="HazardSubCol"
            :headerName="$t('Hazards and Actions')" v-slot="{ row: taskRow }">
            <div v-if="!taskRow.isNewRecord" style="max-width:1500px">
                <div class="collapse multi-collapse collapse-div" :class="'taskRow' + taskRow.ID"
                    :id="'taskRow' + taskRow.ID + '1'">
                    <HazardsCard :taskRow="taskRow" :sjaID="props.sjaID" :itemID="props.itemID" :readOnly="props.readOnly"
                        :participants="props.participants" />
                </div>

                <div class="collapse show multi-collapse" :class="'taskRow' + taskRow.ID"
                    :id="'taskRow' + taskRow.ID + '2'">
                    <HazardsSummaryCard :taskRow="taskRow" :sjaID="props.sjaID" :itemID="props.itemID"
                        :readOnly="props.readOnly" :participants="props.participants" />
                </div>

                <div class="d-flex">
                    <button :title="$t('Add a new hazard to task.')" class="btn btn-sm btn-primary mt-1 px-2 p-1 mx-auto"
                        ref="newHazardRow" type="button" @click="newRow(taskRow)"
                        v-if="!props.readOnly && !taskRow.isNewRecord && expandedItems[taskRow.ID]">
                        <i :title="$t('Add a new hazard.')" class="text-white bi bi-plus-lg"></i>
                        <span class="text-white">{{ $t('New Hazard') }}</span>
                    </button>

                    <button class="mb-1 border rounded-bottom bg-white align-self-start border-top-0"
                        :class="{ 'ms-auto': !expandedItems[taskRow.ID] }" type="button" data-bs-toggle="collapse"
                        :disabled="isButtonDisabled[taskRow.ID]" :data-bs-target="'.taskRow' + taskRow.ID"
                        aria-expanded="false"
                        style="width: 4.6rem; border-color: var(--bs-border-color-translucent); !important"
                        aria-controls="multiCollapseExample1 multiCollapseExample2" @click="toggleExpand(taskRow.ID)">
                        <i v-if="expandedItems[taskRow.ID]" class="bi bi-chevron-up"></i>
                        <i v-else class="bi bi-chevron-down"></i>
                    </button>
                </div>
            </div>
        </OColumn>
    </ODataTable>
    <!-- </template> -->
    <InitialMatrixModal></InitialMatrixModal>
    <FinalMatrixModal></FinalMatrixModal>
</template>

<style scoped>
.inner-table-editable {
    border: 1.5px solid var(--o365-grid-border) !important;
    border-radius: var(--bs-border-radius) !important;
}

.inner-table-editable:focus,
.inner-table-editable:hover {
    outline: 0;
    border: 1.5px solid rgba(70, 130, 180, .25) !important;
    box-shadow: 0px 0px 0px 2px rgba(70, 130, 180, .25) !important;
}

.dropdown-item {
    border: 1.5px solid white !important;
    border-radius: var(--bs-border-radius) !important;
}

.dropdown-item:hover {
    border: 1.5px solid rgba(70, 130, 180, .25) !important;
    box-shadow: 0px 0px 0px 2px rgba(70, 130, 180, .25) !important;
}

.page-container {
    display: flex;
    flex-direction: column;
    min-height: 90vh;
}

div[data-o365-container="N"] {
    position: sticky;
    bottom: 0;
    background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
    border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}
</style>